@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$font-primary: "Poppins", Arial, sans-serif;
$primary: black;

a {
  transition: 0.3s all ease;
  color: $primary;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline !important;
    outline: none !important;
    box-shadow: none;
  }
}
button {
  transition: 0.3s all ease;
  &:hover,
  &:focus {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}
.h1,
.h2,
.h3,
.h4,
.h5 {
  line-height: 1.5;
  font-weight: 400;
  font-family: $font-primary;
  color: black;
}

.ftco-section {
  padding: 7em 0;
}

.ftco-no-pt {
  padding-top: 0;
}
.ftco-no-pb {
  padding-bottom: 0;
}
//HEADING SECTION
.heading-section {
  font-size: 28px;
  color: black;
  small {
    font-size: 18px;
  }
}

//COVER BG
.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

//SIDEBAR
.wrapper {
  width: 100%;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: $primary;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  .h6 {
    color: white;
  }
  &.active {
    margin-left: -250px;
    .custom-menu {
      margin-right: -50px;
    }
  }
  .logo {
    img {
      margin-bottom: 20px;
      width: 120px;
    }
  }
  ul.components {
    padding: 0;
  }
  ul {
    li {
      font-size: 16px;
      > ul {
        margin-left: 10px;
        li {
          font-size: 14px;
        }
      }
      a {
        padding: 10px 0;
        display: block;
        color: rgba(255, 255, 255, 0.8);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        &:hover {
          color: white;
        }
      }
      &.active {
        > a {
          background: transparent;
          color: white;
          text-decoration: underline;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    margin-left: -250px;
    &.active {
      margin-left: 0;
    }
    .custom-menu {
      margin-right: -50px !important;
      top: 10px !important;
    }
  }

  .custom-menu {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 0;
    margin-right: -20px;
    @include transition(0.3s);
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      &.btn-primary {
        background-color: #3d14ff !important;
        border-color: transparent;
        &:hover {
          background-color: #5433ff !important;
        }
      }
    }
  }
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

#sidebarCollapse {
  span {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.btn {
  &.btn-primary {
    background: $primary;
    border-color: transparent;
  }
}

.sidebar-flex {
  @media screen and (min-width: 1200px) {
    height: 100%;
  }

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.button-logout {
  background: linear-gradient(180deg, #f41c40 0%, red 100%);
  box-shadow: -2.04891px -2.04891px 15px 0px #fd5e79 inset,
    0.91063px 0.91063px 2.13324px 0px #fbfbfb inset;
  filter: drop-shadow(3px 3px 9px rgba(239, 91, 144, 0.9));
  color: white;
  outline: none;
  border: none;
  padding: 5px 0;
  border-radius: 5px;
}
