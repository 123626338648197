.customGap {
  gap: 20px;
  display: flex;
}
.customError {
  color: #f41c40;
  position: absolute;
  font-size: 14px;
  margin-top: 2px;
}
.imageMain {
  width: 128px;
  height: 128px;
}
.addImage {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}
.uploadText {
  color: #121212;
  font-size: 14px;
  font-weight: 500;
}
.addImage:hover {
  opacity: 0.7;
}
.addedImage {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  position: relative;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  color: white;
}
.selected-image {
  position: relative;
}
.selected-image:hover .image-overlay {
  opacity: 1;
}
.delImgIcon {
  background: none;
  border: none;
}
.dash {
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 0px;
  margin-top: 29px;
}
.searchBar {
  margin-top: 29px;
}

#phone::-webkit-inner-spin-button,
#phone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
