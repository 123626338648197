.AvatarBlueB {
  border: 6px solid #3d14ff;
  border-radius: 50%;
}
.AvatarBlackB {
  border: 6px solid #000;
  border-radius: 50%;
}
.rankMainUsers {
  position: absolute;
  transform: translate(100px, -24px) !important;
  background: #3d14ff;
  border: 2.6px solid #000;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rankMainUsers2 {
  position: absolute;
  transform: translate(140px, -34px) !important;
  background: #3d14ff;
  border: 3.6px solid #000;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.161px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rankMainUsers3 {
  position: absolute;
  transform: translate(100px, -24px) !important;
  background: #3d14ff;
  border: 2.6px solid #000;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secAvatarImg {
  width: 234px;
  height: 234px;
  border-radius: 50%;
  object-fit: cover;
}
.centeredAvatarBg {
  background: linear-gradient(180deg, #3d14ff 0%, #3d14ff 100%);
  box-shadow: -2.04891px -2.04891px 15px 0px #3d14ff inset,
    0.91063px 0.91063px 2.13324px 0px #3d14ff inset;
  filter: drop-shadow(0px 0px 22px #3d14ff);
  border-radius: 50%;
}
.centeredAvatarImg {
  width: 326px;
  height: 326px;
  border-radius: 50%;
  object-fit: cover;
}
.AvatarNameMain {
  display: flex;
  order: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  margin-right: -39px;
}
.AvatarNameMain2 {
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
}
.AvatarNameMain3 {
  display: flex;
  order: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  margin-left: -39px;
}
.pointsNameMainBox {
  display: flex;
  flex-direction: column;
}
.pointsXpBox {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: baseline;
}
.points {
  color: #fff;
  font-weight: 700;
  font-size: 27px;
  letter-spacing: -0.34px;
}
.xp {
  color: #fff;
  font-size: 11.2px;
  font-weight: 700;
  letter-spacing: 0.057px;
}
.name {
  color: #fff;
  opacity: 0.5;
  letter-spacing: -0.2px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}
.secMain {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.secMain:first-child {
  margin-top: 30px;
}
.otherRanksMain {
  max-width: 766px;
  width: 100%;
  display: flex;
  background: #1b1b1b;
  border-radius: 42px;
  padding: 28.4px 28px 24.8px 30px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}
.avatarNamePointsMain {
  display: flex;
  flex-direction: row;
  gap: 9.1px;
  align-items: center;
}
.smallAvatarBlueB {
  border: 2px solid #3d14ff;
  border-radius: 50%;
}
.smallAvatarBlackB {
  border: 2px solid #000;
  border-radius: 50%;
}
.smallAvatar {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  object-fit: cover;
}
.smallNamePoints {
  display: flex;
  flex-direction: column;
}
.smallName {
  color: #fff;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.28px;
}
.smallPoints {
  color: rgba(255, 255, 255, 0.57);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.22px;
}
.smallRanks {
  width: 55px;
  height: 55px;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(255, 255, 255, 0.02) 100%
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e8e8e8;
  font-size: 17px;
  letter-spacing: 0.129px;
}
.loadMoreDiv {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadMoreButton {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 20px;
}
.loadMoreButton:hover {
  opacity: 0.7;
}
