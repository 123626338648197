#points::-webkit-inner-spin-button,
#points::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
#quantity::-webkit-inner-spin-button,
#quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.uploadImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}
.pointsQuantityField {
  display: flex;
  gap: 20px;
}
.storeAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}
