@import '~bootstrap/scss/bootstrap.scss';

// Main App colors =>
.darkGray {
  color: #121212;
}
.white {
  color: #ffffff;
}
.electricBlue {
  color: #3d14ff;
}
.purple {
  color: #852fff;
}
.yellow {
  color: #fbf227 !important;
}
.orange {
  color: #ed6b29;
}

.cursor-pointer {
  cursor: pointer;
}

.text-underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #111112 !important;
}
table tr th {
  background: #3d14ff !important;
}
.error {
  color: #f41c40;
  padding: 5px;
}
#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  transition: all 0.3s;
}

.page-item .page-link {
  background: #2c3034 !important;
  color: #fff !important;
  border: #1f1e1e;
}

.add-new-button {
  background: linear-gradient(180deg, #3d14ff 0%, #480fc2 100%);
  box-shadow: -2.04891px -2.04891px 15px 0px #4591ea inset,
    0.91063px 0.91063px 2.13324px 0px #fbfbfb inset;
  filter: drop-shadow(3px 3px 11px #5871d8);
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  outline: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 576px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.goback {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.tag-item .text {
  color: #fff;
}
.tags-input-container {
  border: 1px solid rgba(232, 232, 232, 0.3);
  border-radius: 10px;
  font-size: 16px;
  background-color: rgba(232, 232, 232, 0.1);
  padding: 10px;
  width: 100%;
  margin-top: 1em;
}
.tags-input-wrap {
  width: 100%;
}
.tags-input-wrap input {
  width: 100%;
}
.tags-input-content {
  padding: 10px 10px;
  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 10px;
}
.tag-item .close {
  height: 20px;
  width: 20px;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  text-indent: 10px;
  outline: none;

  border: 1px solid rgba(232, 232, 232, 0.3);
  border-radius: 10px;
  font-size: 16px;
  background-color: rgba(232, 232, 232, 0.1);
  color: rgba(255, 255, 255, 1);
}

form input {
  margin: 0;
  border: 0;
  outline: none;
}

form textarea {
  margin: 0;
  border: 0;
  outline: none;
}
/* Styles for the form container */
.custom-form {
  // display: flex;
  // flex-direction: column;
  max-width: 600px;
  padding: 20px;
}

/* Styles for form groups (input fields and button) */
.form-group {
  margin-bottom: 15px;
}

/* Styles for the labels */
.custom-label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #fff;
}

/* Styles for the input fields */
.custom-input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(232, 232, 232, 0.3);
  border-radius: 10px;
  font-size: 16px;
  background-color: rgba(232, 232, 232, 0.1);
  color: #f2f2f2;
}

.select-option{
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(232, 232, 232, 0.3);
  &:last-child{
    border-bottom: none;
  }
}

.option-warpper{
max-height: 200px;
overflow-y: scroll;
}

/* Hide the default file input visually */
.custom-file-label input[type='file'] {
  display: none;
}

/* Style the custom label */
.custom-file-label {
  display: inline-block;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  background-color: #852fff;
  color: #fff;
  border-radius: 10px;
  border: none;
  transition: background-color 0.3s;
}

/* Hover effect for the custom label */
.custom-file-label:hover {
  background-color: #852fff;
}
.selected-file-name {
  margin: 10px 0; /* Add spacing between the label and the selected file name */
  color: #fff; /* Text color for the selected file name */
  font-size: 14px; /* Font size for the selected file name */
}

/* Style for the button */
.danger-button {
  background-color: #e74c3c;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button {
  background: linear-gradient(180deg, #3d14ff 0%, #480fc2 100%);
  box-shadow: -2.04891px -2.04891px 15px 0px #4591ea inset,
    0.91063px 0.91063px 2.13324px 0px #fbfbfb inset;
  filter: drop-shadow(3px 3px 11px #5871d8);
  width: 100%;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-button:disabled {
  opacity: 50%;
  cursor: not-allowed;
}
/* Style the button */
.custom-button {
  width: 100%;
  background-color: #852fff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

/* Hover effect for the button */
.custom-button:hover {
  background-color: #852fff;
}

/* Disable button style */
.custom-button[disabled] {
  background-color: #852fff;
  opacity: 50%;
  cursor: not-allowed;
}

form input:disabled,
form textarea:disabled,
form select:disabled {
  opacity: 0.5;
}

/* loader style */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    margin-right: 5px;
  }
  button {
    margin: 0 10px;
    background: none;
    border: none;
    outline: none;
  }
}

.full-screen-loader {
  height: 80vh;
}

.loader {
  border: 3px solid rgba(232, 232, 232, 0.9);
  border-top: 3px solid #3d14ff;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader style end */

.custom-border {
  border-radius: 10px;
  overflow: hidden;
}

/* Modal delete */

.modal-delete {
  background: black !important;
  color: white;
  border-color: rgb(71, 70, 70) !important;
  .modal-title {
    color: white;
  }
}
/* Modal delete end*/

/* Pagination */
.page-item {
  cursor: pointer;
}

.page-item.disabled {
  opacity: 50%;
  pointer-events: none;
  cursor: auto;
}

/* Pagination end*/

/* Form check input */
.form-check {
  label {
    color: rgba(232, 232, 232, 0.7);
  }
}

/* Form check input end */

/* Breadcrumb item start*/
.breadcrumb-item a,
.breadcrumb-item::before {
  color: #fff !important;
  opacity: 50% !important;
}
/* Breadcrumb item end*/

/* Add button start */
.add-image-button {
  width: 100px;
  height: 150px;
  border: 1px solid #f2f2f2;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.add-image-button h1,
.additional-information-videos {
  color: #f2f2f2;
  opacity: 0.5;
}
/* Add button end */

/* image modal start */
#myImg {
  cursor: pointer;
  transition: 0.3s;
}

.image-show {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  // margin: 0 15px;
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  // background-color: #555;
}

.image-show:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  transform: translate(-140%, -50%);
  background-color: rgba(0, 0, 0);
  opacity: 0.8;
  border-radius: 50%;
  transition: 0.8s;
}

.image-show:hover:after {
  transform: translate(-50%, -50%);
}

.eye {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #fff;
  transition: 0.4s;
  transition-timing-function: ease-in;
}

.image-show:hover .eye {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

/* The Modal (background) */
.my-modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

/* Modal Content (image) */
.my-modal-content {
  margin: auto;
  display: block;
  // width: 80%;
  max-width: 700px;
  height: 70vh;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.my-modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close-img {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close-img:hover,
.close-img:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

/* image modal end */

.delete-button {
  background: none;
  border: none;
  outline: none;
}

/* Report list style */
.view-item {
  color: white;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/* Report list style end */

/* Report list details */
.report-title {
  color: whitesmoke;
}

.custom-label-reports {
  color: #fff;
  opacity: 0.5;
}

.report-details {
  padding: 20px;
  margin: 0 auto;
}

.custom-text-reprorts {
  color: white;
  opacity: 0.7;

  &-align {
    color: white;
    opacity: 0.7;
    margin: 0 0 0 10px;
  }
}

.reports-buttons {
  button:first-child {
    margin-right: 10px;
  }
}

/* Report list details end */

/*Dashboard page style*/
.basic-metrics-range input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(232, 232, 232, 0.3);
  border-radius: 10px;
  font-size: 16px;
  background-color: rgba(232, 232, 232, 0.1);
  color: #f2f2f2;
}

.leaderboard {
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);

  max-width: 490px;
  width: 100%;
  border-radius: 12px;

  header {
    --start: 15%;

    height: 90px;
    background-image: repeating-radial-gradient(
        circle at var(--start),
        transparent 0%,
        transparent 10%,
        rgba(54, 89, 219, 0.33) 10%,
        rgba(54, 89, 219, 0.33) 17%
      ),
      linear-gradient(to right, #5b7cfa, #3659db);
    color: #fff;
    position: relative;
    border-radius: 12px 12px 0 0;
    overflow: hidden;

    .leaderboard__title {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: calc(var(--start) * 0.75);
      transform: translateY(-50%);
      text-transform: uppercase;
      margin: 0;

      span {
        display: block;
      }

      &--top {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 6.5px;
      }

      &--bottom {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 3.55px;
        opacity: 0.65;
        transform: translateY(-2px);
      }
    }

    .leaderboard__icon {
      fill: #fff;
      opacity: 0.35;
      width: 50px;
      position: absolute;
      top: 50%;
      left: var(--start);
      transform: translate(-50%, -50%);
    }
  }

  &__profiles {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 12px 12px;
    padding: 15px 15px 20px;
    display: grid;
    row-gap: 8px;
  }

  &__profile {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
      box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: rgba(0, 0, 0, 0.3);

    &:hover {
      transform: scale(1.2);
      box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
    }
  }

  &__picture {
    max-width: 100%;
    width: 30px;
    box-shadow: 0 0 0 10px #fbf227, 0 0 0 22px #fbf227;
  }

  &__name {
    color: #979cb0;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.64px;
    margin-left: 12px;
  }

  &__value {
    color: #5b7cfa;
    font-weight: 700;
    font-size: 34px;
    text-align: right;

    & > span {
      opacity: 0.8;
      font-weight: 600;
      font-size: 13px;
      margin-left: 3px;
    }
  }
}

.social-leaderboard {
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.7);
  max-width: 490px;
  max-height: 360px;
  width: 100%;
  color: white;
  border-radius: 12px;

  #header {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px 12px 0 0;

    h1 {
      font-size: 24px;
      margin: 0;
    }

    h5 {
      letter-spacing: 3.55px;
      opacity: 0.65;
      font-size: 13px;
    }
  }

  ol {
    width: 100%;
    padding: 5px;
    margin: 0;
    li {
      padding: 16px;
      display: flex;
      margin: 10px 0;
      cursor: pointer;
      &:hover {
        box-shadow: -2.04891px -2.04891px 15px 0px #4591ea inset,
          0.91063px 0.91063px 2.13324px 0px #fbfbfb inset;
        filter: drop-shadow(3px 3px 11px #5871d8);
      }
    }

    li mark {
      width: 50%;
      color: snow;
      background-color: transparent;
    }

    li:before {
      margin-right: 4px;
    }

    li:nth-child(1) {
      background: #6a81e7;
      margin-bottom: 20px;
    }
    li:nth-child(2) {
      background: #5b7cfa;
      margin-bottom: 20px;
    }
    li:nth-child(3) {
      background: #3d60ec;
    }
  }
}

.leaderboard-views {
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);

  max-width: 490px;
  width: 100%;
  border-radius: 12px;

  header {
    --start: 15%;

    height: 90px;
    background-image: repeating-radial-gradient(
        circle at var(--start),
        transparent 0%,
        transparent 10%,
        rgba(216, 219, 54, 0.33) 10%,
        rgba(203, 219, 54, 0.33) 17%
      ),
      linear-gradient(to left, #e2fa5b, #b7db36);
    color: #fff;
    position: relative;
    border-radius: 12px 12px 0 0;
    overflow: hidden;

    .leaderboard-views__title {
      position: absolute;
      top: 50%;
      right: calc(var(--start) * 0.75);
      transform: translateY(-50%);
      text-transform: uppercase;
      margin: 0;

      span {
        display: block;
      }

      &--top {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 6.5px;
      }

      &--bottom {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 3.55px;
        opacity: 0.65;
        transform: translateY(-2px);
      }
    }

    .leaderboard-views__icon {
      fill: #fff;
      opacity: 0.35;
      width: 50px;
      position: absolute;
      top: 50%;
      left: var(--start);
      transform: translate(-50%, -50%);
    }
  }

  &__profiles {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 12px 12px;
    padding: 15px 15px 20px;
    display: grid;
    row-gap: 8px;
  }

  &__profile {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
      box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: rgba(0, 0, 0, 0.3);

    &:hover {
      .leaderboard-views__number-container {
        transform: scale(1.2);
        box-shadow: -2.04891px -2.04891px 15px 0px #4591ea inset,
          0.91063px 0.91063px 2.13324px 0px #fbfbfb inset;
        filter: drop-shadow(3px 3px 11px #5871d8);
      }
    }
  }

  &__picture {
    max-width: 100%;
    width: 30px;
    box-shadow: 0 0 0 10px #5b7cfa, 0 0 0 22px #5b7cfa;
  }

  &__name {
    color: #979cb0;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.64px;
    margin-left: 12px;
  }

  &__number-container {
    background-color: #5b7cfa;
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 24px;
    text-align: center;
  }

  &__value {
    color: #e2fa5b;
    font-weight: 700;
    font-size: 34px;
    text-align: right;

    & > span {
      opacity: 0.8;
      font-weight: 600;
      font-size: 13px;
      margin-left: 3px;
    }
  }
}

.filters {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
  select {
    margin-left: 10px;
    width: 50%;
    @media screen and (max-width: 576px) {
      width: 100%;
      margin: 10px 0;
    }
  }
}

/*Dashboard page end*/

// Table Styles =>
.table-striped2 tbody tr:nth-of-type(odd) {
  --bs-table-bg: #121212 !important;
}
.table-striped2 tbody tr:nth-of-type(even) {
  --bs-table-bg: #b9b9b9 !important;
  --bs-table-color: #000 !important;
}

.suggest-tags {
  position: absolute;
  background-color: red;
  max-height: 10rem;
  overflow-y: scroll;
  background-color: #212529;
  opacity: 0.8;
  border-radius: 8px;

  ul {
    list-style-type: none;
    margin: 10px 10px 10px 10px !important;
    padding-left: 10px !important;
  }

  li {
    cursor: pointer;
  }
  li:hover {
    opacity: 0.6 !important;
  }
}

.suggest-tags::-webkit-scrollbar-track, .option-warpper::-webkit-scrollbar-track {
  background-color: transparent;
}

.suggest-tags::-webkit-scrollbar, .option-warpper::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.suggest-tags::-webkit-scrollbar-thumb, .option-warpper::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid rgba(0, 0, 0, 0.5);
}

.reset-filters {
  margin-bottom: 0 !important;
  margin-left: 5px;
  color: white;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}



/** Quest details tabs **/

.border-active-tabs{
padding-bottom: 2px;
  border-bottom: 1px solid #3d14ff;
}

/** Quest details tabs end **/


/** Dule video card **/
.custom-card{
  width: 100%;
  max-width: 400px;
  border-radius: 25px;
}
.duel-posts{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.duel-post{
  display: flex;
  justify-content: center;
  width: 100%;
  height:  50%;
  img{
    width: 70%;
    max-height:  200px;
    margin:  0 auto;
  }

  img.background {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
    filter: blur(2px);
    }



}


.user-image{
  width: 40px !important;
  height: 40px;
  border: 1px solid #F41C40;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.user-name{
  margin: 0;
  color: white;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.modal-duel-video{
  max-width: 200px;
  margin:  0 auto;
  // height: 50%;
  position: relative;
}


/** Duel video card end **/


.load-more-hover:hover{
opacity: 0.5;
}