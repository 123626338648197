.card {
  border: none;
  border-top: 5px solid  #480FC2 !important;
  background: #161616 !important;
  color: #fff !important;
}
p {
  font-weight: 600;
  font-size: 15px;
}
.fab {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #2a284d;
  height: 40px;
  width: 90px;
}
.fab:hover {
  cursor: pointer;
}

.division {
  float: none;
  position: relative;
  margin: 30px auto 20px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.division span {
  font-weight: 600;
  font-size: 14px;
}
.myform {
  padding: 0 25px 0 33px;
}
.form-control {
  border: 1px solid #57557a !important;
  border-radius: 3px;
  background: #212042 !important;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: white !important;
}
.form-control:focus {
  border: 1px solid #fff !important;
  border-radius: 3px;
  box-shadow: none;
  background: #212042 !important;
  color: #fff;
  letter-spacing: 1px;
}
.form-control::placeholder {
  color: #fff !important;
}
.bn {
  text-decoration: underline;
}
.bn:hover {
  cursor: pointer;
}
.form-check-input {
  margin-top: 8px !important;
}
.btn-primary {
  background:  #3D14FF !important;

  border: none;
  border-radius: 5px;
}
.btn-primary:focus {
  box-shadow: none;
  border: none;
}
small {
  color: #f2ceff;
}

.inputs input {
  width: 40px;
  height: 40px;
  color: white;
}

.loginway{
  text-transform: uppercase;
}

@media (min-width: 767px) {
  .bn {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .form-check {
    text-align: center;
  }
  .bn {
    text-align: center;
    align-items: center;
  }
}
@media (max-width: 450px) {
  .fab {
    width: 100%;
    height: 100%;
  }
  .division .line {
    width: 50%;
  }
}
