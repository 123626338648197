.searchStatus {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }
  .dash {
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-top: 29px;
  }
  .searchBar {
    margin-top: 29px;
  }
  